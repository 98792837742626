import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReactComponent as LoadingIcon } from "./assets/loadingIcon.svg";
import GrandLayout from "./components/GrandLayout";
import PrivateRoute from "components/router/PrivateRoute";

const CreateAccount = React.lazy(
  () => import("./screens/onboarding/CreateAccount")
);
const Notifications = React.lazy(
  () => import("./screens/dashboard/Notifications")
);
const NotificationDetails = React.lazy(
  () => import("./screens/dashboard/NotificationDetails")
);
const SelectAsset = React.lazy(() => import("./components/home/Assets"));
const SendAsset = React.lazy(() => import("./components/home/SendAsset"));
const SetAmount = React.lazy(() => import("./components/home/SetAmount"));

const PersonalDetails = React.lazy(
  () => import("./screens/onboarding/PersonalDetails")
);
const UpdateProfile = React.lazy(
  () => import("./components/settings/EditProfile")
);
const NoPage = React.lazy(() => import("./screens/NoPage"));
const ForgotPassword = React.lazy(
  () => import("./screens/onboarding/ForgotPwd")
);
const Wallet = React.lazy(() => import("./components/home/Wallet"));
const AddNewBank = React.lazy(() => import("./components/settings/AddNewBank"));
const VerifyEmail = React.lazy(
  () => import("./screens/onboarding/VerifyEmail")
);
const Kyc = React.lazy(() => import("./screens/dashboard/Kyc"));
const CreatePin = React.lazy(() => import("./screens/onboarding/CreatePin"));
const Referral = React.lazy(() => import("./screens/dashboard/Referral"));
const ReferralHistory = React.lazy(
  () => import("./screens/dashboard/ReferralHistory")
);
const VerifyCode = React.lazy(() => import("./screens/onboarding/VerifyCode"));
const Survey = React.lazy(() => import("./screens/dashboard/Survey"));
const SurveySuccess = React.lazy(
  () => import("./components/survey/SurveySuccess")
);
const SetNewPwd = React.lazy(() => import("./screens/onboarding/SetNewPwd"));
const ConfirmPin = React.lazy(() => import("./screens/onboarding/ConfirmPin"));
const KycProgPage = React.lazy(() => import("./screens/dashboard/KycProgPage"));
const Login = React.lazy(() => import("./screens/onboarding/Login"));
const SupportMobile = React.lazy(
  () => import("./components/support/SupportMobile")
);

const Transactions = React.lazy(
  () => import("./screens/dashboard/Transactions")
);
const TransactionDetails = React.lazy(
  () => import("./components/transactions/TransactionDetails")
);
const Home = React.lazy(() => import("./screens/dashboard/Home"));
const Settings = React.lazy(() => import("screens/dashboard/Settings"));

const Routers = () => {
  return (
    <div>
      {/* <BrowserRouter> */}
      <GrandLayout>
        <Suspense
          fallback={
            <div className="flex justify-center mt-[350px] z-50">
              <LoadingIcon className="suspense-loading-icon" />
            </div>
          }
        >
          <Routes>
            <Route index element={<CreateAccount />} />
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />

            <Route
              path="/add-new-bank"
              element={
                <PrivateRoute>
                  <AddNewBank />
                </PrivateRoute>
              }
            />
            <Route
              path="/survey"
              element={
                <PrivateRoute>
                  <Survey />
                </PrivateRoute>
              }
            />
            <Route
              path="/survey/survey-success"
              element={
                <PrivateRoute>
                  <SurveySuccess />
                </PrivateRoute>
              }
            />
            <Route
              path="/kyc/kyc-progress"
              element={
                <PrivateRoute>
                  <KycProgPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/wallet"
              element={
                <PrivateRoute>
                  <Wallet />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/update-profile"
              element={
                <PrivateRoute>
                  <UpdateProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <PrivateRoute>
                  <Notifications />
                </PrivateRoute>
              }
            />
            <Route
              path="/assets"
              element={
                <PrivateRoute>
                  <SelectAsset />
                </PrivateRoute>
              }
            />
            <Route
              path="/assets/:walletId"
              element={
                <PrivateRoute>
                  <SendAsset />
                </PrivateRoute>
              }
            />
            <Route
              path="/assets/set-amount"
              element={
                <PrivateRoute>
                  <SetAmount />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/support"
              element={
                <PrivateRoute>
                  <SupportMobile />
                </PrivateRoute>
              }
            />
            <Route
              path="/transactions"
              element={
                <PrivateRoute>
                  <Transactions />
                </PrivateRoute>
              }
            />
            <Route
              path="/transactions/:transactionID"
              element={
                <PrivateRoute>
                  <TransactionDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/referral-program"
              element={
                <PrivateRoute>
                  <Referral />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/referral-program/history"
              element={
                <PrivateRoute>
                  <ReferralHistory />
                </PrivateRoute>
              }
            />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route
              path="/notifications/:notificationID"
              element={<NotificationDetails />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/personal-details" element={<PersonalDetails />} />
            <Route path="/create-pin" element={<CreatePin />} />
            <Route path="/confirm-pin" element={<ConfirmPin />} />
            <Route path="/verify-code" element={<VerifyCode />} />
            <Route path="/set-new-password" element={<SetNewPwd />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/kyc"
              element={
                <PrivateRoute>
                  <Kyc />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NoPage />} />
            {/* <Route path="/transactions" element={<Transactions />} />
            <Route path="/home" element={<Home />} />
            <Route path="/settings" element={<Settings />} /> */}
            {/* <Route path="/add-new-bank" element={<AddNewBank/>} />
            <Route path="/wallet" element={<Wallet/>} /> */}
          </Routes>
        </Suspense>
      </GrandLayout>
      {/* </BrowserRouter> */}
    </div>
  );
};

export default Routers;
