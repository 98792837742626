import React from "react";
import "./Sidebar.css";
import SidebarButton from "components/sidebar/SidebarButton";
import logoutIcon from "../../assets/logoutIcon.svg";
import { sideBarItems } from "components/sidebar/SidebarItems";
import { useAuth } from "configuration/AuthContext";

const Sidebar = () => {
  const { logout } = useAuth();
  return ( 
    <div>
      <aside className="w-full bg-white hidden border-r border-gray-300 h-[100vh] max-h-[100vh] overflow-y-auto md:flex flex-col pr-10 justify-start items-end">
        <div className="flex flex-col mt-[24px]">
          {sideBarItems.map((item, index) => (
            <SidebarButton
              key={index}
              to={item.to}
              icon={item.icon}
              title={item.title}
            />
          ))}
        </div>

        <div>
          <div className="w-full mt-20 flex md:block justify-center">
            <button
              onClick={() => logout()}
              className={
                "md:w-[168px] w-20 md:justify-start md:mb-5 font-medium rounded text-sm text-black flex justify-center items-center gap-2  py-3 px-5 hover:scale-[1.1] transform duration-200 "
              }
            >
              <span>
                <img src={logoutIcon} alt="Logout Icon" />
              </span>
              <p>Log Out</p>
            </button>
          </div>
        </div>

        {/* <button
            onClick={() => logout()}
            className=" hover:scale-[1.1] mt-20 bg-yellow-100 w-[30%] gap-2 py-4 transform duration-200 justify-center font-medium flex  text-sm "
          >
            <span>
              <img src={logoutIcon} alt="Logout Icon" />
            </span>
            <p>Log Out</p>
          </button> */}
      </aside>
      <aside className=" md:hidden z-50 md:z-0  fixed bottom-0 left-0 right-0 px-4 py-2 bg-white flex items-center  justify-between ">
        <div className="flex justify-between w-full items-center">
          {sideBarItems.map((item, index) => (
            <SidebarButton
              key={index}
              to={item.to}
              icon={item.icon}
              title={item.title}
            />
          ))}
        </div>
        {/* <div className="mt-20">
          <SidebarButton
            to="/login"
            icon={<img src={logoutIcon} alt="Logout Icon" />}
            title="Log Out"
          />
        </div> */}
      </aside>
    </div>
  );
};

export default React.memo(Sidebar);
