import React, { useEffect, useState } from "react";
import { supportItems } from "../support/SupportItems";
import SupportButton from "../support/SupportButton";
import instagramIcon from "../../assets/instagram.svg";
import twitterIcon from "../../assets/twitterIcon.svg";
import infoIcon from "../../assets/infoSquare.svg";
import arrowRight from "../../assets/arrowRight.svg";
import Faq from "./Faq";
import SurveyModal from "./SurveyModal";
import { useLocation } from "react-router-dom";
import { cwitoApi } from "configuration/AxiosConfig";
import { useAuth } from "configuration/AuthContext";
import { QuestionsEntity, SurveyDetailsTypes } from "types/SurveyDetailsTypes";

function Support() {
  const location = useLocation();

  const [view, setView] = useState<number>(1);
  const [surveyIsSubmitted, setSurveyIsSubmitted] = useState<number>(1);
  const handleView = (view: number) => {
    setView(view);
  };
  const { storedToken, setSurveyId, setSurvey, setSurveyLoading } = useAuth();

  useEffect(() => {
    const getSurvey = async () => {
      try {
        const response = await cwitoApi.get(`/user/get-survey`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setSurvey(response?.data?.survey?.questions || []);
        setSurveyId(response?.data?.survey?._id || "");
        setSurveyLoading(false);
        // setSurveyIsSubmitted(response?.data?.hasSubmitted);
        setSurveyIsSubmitted(response?.data.survey.hasSubmitted);
      } catch (error) {
        console.error("Error fetching survey:", error);
      }
    };
    getSurvey();
  }, [storedToken]);
  

  // console.log(surveyIsSubmitted);
  return (
    <aside className="md:h-[100vh] relative bg-white px-4   md:max-h-[100vh] md:border-l border-gray-300 overflow-x-hidden pt-5 pb-20 md:py-5 overflow-y-auto md:pl-10 ">
      {view === 1 && (
        <div className="">
          <header className="flex justify-start w-full">
            <h1 className="font-bold text-[#1E194E] text-lg">Support</h1>
          </header>
          <div className="flex justify-between md:justify-start  md:flex md:flex-col mt-2 ">
            {supportItems.map((item, index) => (
              <SupportButton
                key={index}
                to={item.to}
                icon={item.icon}
                title={item.title}
                subtitle={item.subtitle}
              />
            ))}
          </div>
          <button
            onClick={() => handleView(2)}
            className="flex justify-between mt-6 hover:bg-[#d0cfcf]/50 items-center w-full md:w-[309px] rounded-lg  bg-[#F8F8F8] p-4 "
          >
            <div className="flex justify-start gap-2 items-center">
              <img src={infoIcon} alt="asset icon" />
              <div className="flex flex-col text-start">
                <p className="md:text-sm font-semibold text-[10px]">FAQs</p>
                <p className="text-xs">Frequently asked questions</p>
              </div>
            </div>
            <img src={arrowRight} alt="asset icon" />
          </button>
          <section className="flex flex-col gap-4  justify-start w-full mt-4">
            <h1 className="font-bold text-[#1E194E] text-sm md:text-lg text-start">
              Socials
            </h1>
            <div className="flex flex-col text-xs md:flex gap-2 md:flex-row w-full md:w-auto md:justify-start md:items-center">
              <a
                href="https://www.instagram.com/usecwito_?igsh=eHhsa2ZlNXp0amh3"
                target="blank"
                rel="norefferer"
                className="md:bg-white gap-2 items-center flex md:block bg-[#F8F8F8] p-4 rounded-lg"
              >
                <img src={instagramIcon} alt="instagram Icon" />
                <p className="md:hidden">Instagram</p>
              </a>
              <a
                href="https://x.com/UseCwito"
                target="blank"
                rel="norefferer"
                className="md:bg-white gap-3 items-center flex md:block bg-[#F8F8F8] p-4 rounded-lg"
              >
                <img src={twitterIcon} alt="twitter Icon" />
                <p className="md:hidden">X</p>
              </a>
            </div>
          </section>
          {!location.pathname.includes("survey") && !surveyIsSubmitted && (
            <div className="absolute bottom-16">
              <SurveyModal />
            </div>
          )}
        </div>
      )}
      {view === 2 && <Faq handleView={handleView} />}
    </aside>
  );
}
export default Support;
